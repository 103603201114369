.portfolio .item{
    height: 420px;
    position: relative;
    padding: 0 40px;
    cursor: pointer;
    transition: var(--transition);
}
.portfolio .item *{
    z-index: 1;
}
.portfolio .item::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 0.6) 0%, rgba(85, 179, 213, 0.7) 100%);
}
.portfolio .item-title{
    border-bottom: 1px solid var(--clr-white);
    padding-bottom: 20px;
}
.portfolio .item-list{
    margin-top: 40px;
    row-gap: 40px;
}
@media screen and (min-width: 768px) {
    .portfolio .item-list{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;
    }
    .portfolio .section-t{
        text-align: center;
    }
    .portfolio .section-t::before{
        left: 50%;
        transform: translateX(-50%);
    }
    
}
@media screen and (min-width: 1200px) {
    .portfolio .item-list{
        grid-template-columns: repeat(3, 1fr);
    }
    .portfolio .item *{
        opacity: 0;
        transition: var(--transition);
    }
    .portfolio .item::after {
        opacity: 0;
        transition: var(--transition);
    }
    .portfolio .item:hover *{
        opacity: 1;
    }
    .portfolio .item:hover::after{
        opacity: 1;
    }
    
}