.about-content{
    row-gap: 40px;
}
.container-liofilizacija{
    max-width: 1920px;
    max-height: 1080px;
    background: url("../../assets/images/liofilizacija.jpg") center/cover no-repeat;
    padding: 0 4rem;
    font-family: QuickSand;
}
/* p {
    width: 900px;
    font-family: QuickSand;
} */

@media screen and (min-width: 768px) {
    .about-content{
        text-align: left;
    }
}
.paddings{
    padding-top: 5%;
    padding-left: 10%;
}

@media screen and (min-width: 992px) {
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
    .pAbout {
        width: 900px!important;
        font-family: QuickSand;
        margin-left: 8%;
        color: rgb(78, 59, 55);;
    }
    .textNormal{
        font-size: 36px;
        font-weight: 500;
        line-height: 114.4%;
        padding-top: 3%;
    
    }
    .textSemiBold{
        padding-left: 28px;
        font-size: 36px;
        font-weight: 600;
        line-height: 114.4%;
        padding-top: 3%;
        text-align: center;
    }
}

@media screen and (min-width: 600px) {
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
    .pAbout {
        width: 700px!important;
        font-family: QuickSand;
    }
    .textNormal{
        font-size: 30px;
        font-weight: 500;
        line-height: 114.4%;
        padding-top: 3%;
    
    }
    .textSemiBold{
        font-size: 30px;
        font-weight: 700;
        line-height: 114.4%;
        padding-top: 5%;
        padding-bottom: 10%;
    }
}