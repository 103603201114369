  /* Add rounded corners to the top left and the top right corner of the image */
  .container-ItemSection{
    background: url("../../assets/images/ooga.jpg") center/cover no-repeat;
    padding: 0 30rem;
    font-family: QuickSand;
    max-height: 100%!important;
    width: auto;
    justify-content: center;
}
.itemSectionImg{
  width: 350px;
  height: 350px;
  border-radius: 10px;
}

  .itemSectionCard {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 350px;
    height: 350px;
  }
  
  /* On mouse-over, add a deeper shadow */
  .itemSectionCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */

  .btn-ItemSection{
    align-self: center!important;
    font-family: QuickSand;
    font-weight:bold;
    display: inline-block;
    height: 60px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-white);
    font-size: 30px;
    background-color: rgb(152, 149, 106);
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    border-radius: 10px;
    /* opacity: 0.95; */
    box-shadow: 4px 4px 4px black;
    margin-top: 20%;
    
}
.btn-ItemSection:hover {
    /* background-color: #3e8e41; */
    transform: scale(1.1);
    opacity: 90%;
}

.h5ItemSection {
  padding-top: 17.5%;
  font-size: 36px;
  line-height: 114.4%;
  font-weight: 500;
  padding-bottom: 7%;
  text-align: center;
  color: rgb(78, 59, 55);;
}

@media screen and (min-width: 500px) {
  .container-ItemSection{
    padding: 0 10rem;
    height: auto!important;
    background-position:left;
    min-width: 100%;
  }
  .itemSection .item-list{
      grid-template-columns: repeat(1, 1fr);
      row-gap: 30px;
      column-gap: 0px;
      justify-items: center;
  }
  .h5ItemSection {
    padding-top: 17.5%;
    font-size: 24px;
    line-height: 114.4%;
    font-weight: 500;
    padding-bottom: 7%;
    text-align: center;
    color: rgb(78, 59, 55);
  }
}

@media screen and (min-width: 1500px) {
  .container-ItemSection{
    padding: 0 30rem;
    height: 1080px!important;
    min-width: 100%;
  }
  .h5ItemSection {
    padding-top: 17.5%;
    font-size: 36px;
    line-height: 114.4%;
    font-weight: 500;
    padding-bottom: 10%;
    text-align: center;
    color: rgb(78, 59, 55);
  }
  .itemSection .item-list{
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10px;
      display: grid;
      justify-content: center;
  }
  .itemSectionCard {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    width: 350px;
    height: 350px;
  }
}