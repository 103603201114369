.itemCard {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    width: 100%;
    height: 200px;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    background-color: white;
    border-radius: 10px;
  }

  /* Add rounded corners to the top left and the top right corner of the image */
  .imgItemCards {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 20px;
    width: 230px;
    height: 180px;
    border-radius: 10px;
  }

.itemGrid{
  margin-top: 2%;
  padding-top: 3%;
  width: 65%;
  height: auto;
  background-color: rgb(78, 59, 55);
  border-radius: 10px;
  justify-content: center;

}
.textBlock{
  clear: both;
}
  .itemCard {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */

  .linkStyle{
    color: rgb(152, 149, 106);
    text-decoration: underline 2px;
    line-height: 114.4%;
    letter-spacing: 4%;
  }
  .itemText{
    font-size: 1.3vw;
    font-weight: 200;
    line-height: 114.4%;
    letter-spacing: 4%;
    width: 600px;
    color: rgb(78, 59, 55);
    text-align: left;
  }
  .priceTag{
    font-size: 2vw;
    font-weight: bold;
    color: rgb(78, 59, 55);
    width: 15%;
    padding-right: 5%;
  }
  .h5Products{
    font-size: 6vw;
    font-weight: 500;
    padding-top: 10%;
    padding-left: 16%;
    padding-bottom: 0%;
  }
  .h4Products{
    font-size: 2.2vw;
    line-height: 114.4%;
    letter-spacing: 4%;
    font-weight: 600;
    color: rgb(78, 59, 55);;
  }
  .p-title{
    padding-left: 17%;
    margin-top: 3%;
    line-height: 114.4%;
  }
  
  /* Add some padding inside the card container */
  .container-cards {
    min-height: 100vh;
    background: rgb(237, 227, 225);
    font-family: QuickSand;
    padding-bottom: 100px;
  }
  .innerContainer {
    justify-content: center;
  }

.btn{
    background-color: var(--clr-robin-blue);
    color: var(--clr-white);
    text-transform: uppercase;
    padding: 16px 32px;
    letter-spacing: 1px;
    border-radius: 3px;
    margin-top: 10px;
}

