.navbar{
    position: fixed;
    top: 0;
    left: 0;
    height: 65px;
    margin: 0 0 !important;
    background-color: var(--clr-white);
}
.navbar-brand{
    color: var(--clr-white);
}
.container-inner {
    max-width: 1920px;
    margin: 0 0;
    padding: 0 6rem;
  }
.fs-30{
    font-size: 30px;
    color: rgb(78, 59, 55);
    font-weight: 600;
}
.hamburger-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 26px;
    width: 34px;
    cursor: pointer;
    z-index: 10;
    color: black;
}
.bar-top, .bar-middle, .bar-bottom{
    height: 3px;
    background-color: var(--clr-black);
    transform-origin: left;
    transition: var(--transition);
}
.hamburger-menu-change .bar-top{
    transform: rotate(42deg);
}
.hamburger-menu-change .bar-middle{
    transform: translateX(1rem);
    opacity: 0;
}
.hamburger-menu-change .bar-bottom{
    transform: rotate(-42deg);
}
.navbar-collapse{
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--clr-white);
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateX(100%);
    transition: var(--transition);
}
.show-navbar-collapse{
    transform: translateX(0);
}
.navbar-collapse-content{
    padding: 40px;
}
.navbar-nav{
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
}
.navbar-nav li{
    margin: 20px 0;
    font-size: 24px;
    font-weight: 500;
    transition: var(--transition);
}
.navbar-nav li:hover{
    color: var(--clr-black);
}
.navbar-social li{
    margin-right: 10%;
    font-size: 24px;
}
.navbar-btns{
    margin-top: 2.4rem;
}
.navbar-btns button{
    border: 2px solid var(--clr-robin-blue);
    transition: var(--transition);
}
.navbar-btns button span{
    margin-left: 8px;
}
.navbar-btns button:hover{
    background-color: var(--clr-robin-blue);
}

@media screen and (min-width:578px) {
    .navbar-collapse{
        width: 320px;
    }
}
@media screen and (min-width: 992px) {
    .navbar-collapse{
        transform: translateX(0);
        position: relative;
        background-color: transparent;
        box-shadow: none;
        top: 0;
        height: auto;
        width: 100%;
        margin-right: 60px;

    }
    .navbar-collapse-content{
        padding: 0;
        display: flex;
        align-items: center;
    }
    .hamburger-menu{
        display: none;
    }
    .navbar-nav{
        display: flex!important;
        margin-bottom: 0;
    }
    .navbar-btns{
        margin-top: 0;
        margin-left: 28px;
    }
    .navbar-nav li{
        margin: 0;
        font-size: 18px;
        width: auto;
        font-weight: 500;
        margin-left: 70px;
    }
    .navbar-nav li:hover{
        color: rgb(152, 149, 106);
    }
.websiteName{
    font-weight: 500;
}
    .navbar-social{
        margin-left: 60px;
    }
    .navbar-social li{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
    }
    .navbar-social li:hover{
        color: rgb(78, 59, 55);;
    }
}