.contact .contact-content{
    row-gap: 60px;
    padding-top: 10%;
}
.contactContainer {
    min-width: 100%;
    min-height: 100%;
    background-color: rgb(152, 149, 106);
    font-family: QuickSand;
    align-items: start;
}
.contact .form-control{
    height: 56px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: var(--clr-black);
    width: 100%;
    margin-bottom: 0;
    color: var(--clr-white);
    font-family: inherit;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
}
.contact .form-control-text{
    display: block;
    font-size: 16px;
    color: var(--clr-white);
    text-align: left;
    margin-bottom: 20px;
    margin-top: 6px;
}
.contact .submit-btn{
    background-color: var(--clr-robin-blue);
    color: var(--clr-white);
    text-transform: uppercase;
    padding: 16px 32px;
    letter-spacing: 1px;
    border-radius: 3px;
    margin-top: 10px;
}
.contact .submit-btn:hover{
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
}
.contact .map-content{
    margin-top: 80px;
}
h3{
    text-decoration: underline 5px;
    font-weight: 400;
    font-size: 90px;
}

.info{
    margin-top: -10px;
}
.info .item{
    padding: 40px;
    cursor: pointer;
    transition: var(--transition);
    position: relative;
}
.info .item::after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 5px;
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgb(152, 149, 106) 0%, rgb(152, 149, 106) 100%);
    transition: var(--transition);
}
.info .item:hover::after{
    width: 100%;
}
.info .item-info-text{
    font-size: 25px;
    margin-bottom: 10px;
}
.info .item-icon{
    font-size: 45px;
}
.info .item-list{
    row-gap: 30px;
    padding-top: 10%;
}
.info .item-link{
    margin-top: 18px;
    display: inline-block;
    transition: var(--transition);
}
.info .item:hover .item-link{
    color: var(--clr-white);
}
.ul li {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 50px;
}

@media screen and (min-width: 768px){
    .info .item-list{
        grid-template-columns: repeat(1, 1fr);
        column-gap: 30px;
        row-gap: 50px;
    }
}

@media screen and (min-width: 1200px){
    .info .item-list{
        grid-template-columns: repeat(1, 1fr);
        column-gap: 30px;
        row-gap: 50px;
    }
    .contactContainer {
        padding-left: 20%;
    }
}

@media screen and (min-width: 992px){
    .contact .contact-content{
        grid-template-columns: repeat(1, 1fr);
        column-gap: 40px;
        row-gap: 50px;
        align-items: center;
    }
}