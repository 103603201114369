.header{
    min-height: 100vh;
    background: url("../../assets/images/main_bg.jpg") center/cover no-repeat;
    padding: 0 4rem;
    font-family: QuickSand;
    max-width: 1920px;
    max-height: 1080px;
}

.header-content .text-lead{
    margin: 2rem 0 7rem 0;
    color: rgb(78, 59, 55);
    line-height: 100%;
    font-weight: 400;
}

.header-title{
    width: 935px;
    font-size: 90px;
    font-weight: 400;
    line-height: 100%;
    color: rgb(78, 59, 55);
    text-transform: none;
}

.header-btn{
    align-self: center!important;
    font-family: QuickSand;
    font-weight:bold;
    display: inline-block;
    min-height: 50px;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-white);
    font-size: 30px;
    background-color: rgb(152, 149, 106);
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    margin: 4px 2px;
    border-radius: 10px;
    /* opacity: 0.95; */
    box-shadow: 4px 4px 4px black;
    
}
.header-btn:hover {
    /* background-color: #3e8e41; */
    transform: scale(1.1);
    opacity: 90%;
}
.header-btn span{
    margin-left: 10px;
}

@media screen and (min-width: 500px) {
    .container-inner-header {
        width: auto;
        margin-left: 0!important;
        margin-top: 0!important;
      }
      .header{
        min-height: 800px;
        background-position:center;
      }
    .header-title{
        font-size: 60px;
        width: auto;
    }
    .header-content *{
        max-width: auto;
    }
}

@media screen and (min-width: 1200px) {
    .container-inner-header {
        width: 935px!important;
        margin-left: 300px!important;
        margin-top: 70px!important;
      }
      .header-title{
        width: 935px;
        font-size: 90px;
        font-weight: 400;
        line-height: 100%;

        /* background: linear-gradient(to right, var(--clr-robin-blue), var(--clr-crazy-blue)); */
        /* -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
    }
    .header{
        min-height: 100vh;
    }
    .header-content *{
        max-width: 920px;
    }
}